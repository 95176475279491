@import "../../theme/styles/base/ovante_variables.scss";

.page-program-intro {
  // .register-header {
  //   height: 390px;
  //   background: linear-gradient(84.62deg, #1e9454 -0.01%, #066130 99.69%);
  //   margin-bottom: 0;
  //   .register-header__withbutton {
  //     top: 29px;
  //     z-index: 1003;
  //   }
  //   &::before {
  //     content: " ";
  //     background: url("../../assets/images/o-device-program-intro.svg")
  //       no-repeat;
  //     position: relative;
  //     background-position: bottom;
  //     display: block;
  //     height: 100%;
  //     max-width: 266px;
  //     margin: 0 auto;
  //     background-size: contain;
  //     z-index: 1;
  //     bottom: 0px;
  //   }
  // }
  .page-content {
    z-index: 1002;
  }
}

.program-header {
  position: relative;
  height: 390px;
  // background: linear-gradient(84.62deg, #1e9454 -0.01%, #066130 99.69%);
  margin-bottom: 0;
  @media screen and (min-width: 560px){
    height: 460px;
  }
  &::before {
    content: " ";
    background: url("../../assets/images/o-device-program-intro.svg") no-repeat;
    position: relative;
    background-position: bottom;
    display: block;
    height: 100%;
    max-width: 266px;
    margin: 0 auto;
    background-size: contain;
    z-index: 1;
    bottom: 0px;
    @media screen and (min-width: 560px){
      bottom: 35px;
    }
  }
  .ion-button__back-btn {
    position: absolute;
    top: 20px;
    left: 20px;
  }
  .expert-default {
    position: absolute;
    width: 213px;
    height: 364px;
    bottom: -25px;
    z-index: 1000;
    left: 50%;
    transform: translateX(-50%);
    @media screen and (min-width: 560px){
      height: 450px;
    }
  }
  &::after {
    content: " ";
    background: url("../../assets/images/header-arch.png") no-repeat;
    position: absolute;
    background-position: bottom;
    display: block;
    height: 52px;
    background-size: contain;
    z-index: 1001;
    bottom: 0;
    width: 100%;
    @media screen and (min-width: 560px){
      height: 92px;
    }
  }
}

.page-content__container {
  text-align: center;
}

.program-title,
.program-popup-title {
  font-size: 1.375rem;
  line-height: 1.925rem;
}
.expert-name {
  font-size: 0.875rem;
  line-height: 1.138rem;
}
.program-description,
.program-popup-description {
  font-size: 0.875rem;
  line-height: 1.225rem;
}
.program-start-btn {
  height: 39px;
  margin: 0 auto;
  max-width: 251px;
  letter-spacing: 0.01em;
  margin-bottom: 18px;
}
.other-program-back {
  cursor: pointer;
}
.other-program-back,
.program-popup__no-change {
  font-size: 1rem;
  line-height: 1.4rem;
  text-decoration: underline;
}
.program-intro-popup {
  .popover-content {
    padding: 40px 25px;
    border-radius: 8px;
  }
}
.program-popup-btn {
  height: 39px;
  margin: 0 auto;
  max-width: 93px;
  letter-spacing: 0.01em;
}
.success-icon {
  width: 50px;
  height: 50px;
  max-width: 100%;
  margin: 0 auto;
}
.page-intro-video {
  padding: 0;
  margin-top: -20px;
  position: relative;
}
.stretch-container {
  margin: 0 -20px;
  padding: 30px 20px;

  .page__section-title {
    margin-bottom: 5px;
  }
}
