@import "../../theme/styles/base/ovante_variables.scss";

.page-notification{
    --ion-background-color: #ffffff;
    &.page-to-do-orange{
        --ion-background-color: #FDF6F1;
    }
    .register-header {
        //background-color: #73CBE5;
        margin-bottom: 0;
        background-color: transparent;
        background: radial-gradient(105% 45% at bottom, transparent 50%, $vDeepSkyBlue 52%);
    }
}
.page-content__unread-notification{
    background: $vLightOrnage;
    margin-top: -20px;
    padding: 20px 20px 30px;
}
.old-notification__title{
    font-size: 0.875rem;
    line-height: 1.225rem;
}