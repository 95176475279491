@import "../../theme/styles/base/ovante_variables.scss";

.page-community-story{
  &.page-review-story{
    .community-story__video-container{
      .ion-button__back-btn{
        position: absolute;
        top: 10px;
        left: 15px;
        z-index: 1;
      }
    }
  }
}