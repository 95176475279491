@import '../../theme/styles/base/ovante_variables.scss';

.home-header__container{
  //background-color: #73CBE5;
  background: linear-gradient(84.35deg, #73CBE5 0%, #45B7D9 98.86%);
  padding: 20px;
  position: relative;
  height: 150px;
  margin: 0 -20px;
  &::after{
      content: ' ';
      background: url("../../assets/images/o-device-program-intro.svg") no-repeat;
      position: absolute;
      display: block;
      height: 146px;
      width: 92px;
      background-size: cover;
      right: 0px;
      z-index: 1;
      top: 21px;
    }
    &::before{
      content: ' ';
      height: 100%;
      width: 100%;
      background: url('../../assets/images/header-arch.png') no-repeat;
      background-position: bottom;
      background-size: contain;
      position: absolute;
      z-index: 3;
      bottom: 0;
      left: 0;
    }
}
.home-header__details{
  width: 75%;
}
.logo{
  display: inline-block;
}
.header-details-logo{
  width: 69px;
  height: 16px;
}
.home-header__title{
  font-size: 1.125rem;
  line-height: 1.463rem;
}
.profile-name{
  font-size: 0.875rem;
  line-height: 1.138rem;
}
.profile-img {
  width: 79px;
  height: 102px;
  position: absolute;
  bottom: 0px;
  right: -5px;
  z-index: 2;
}
.home-profile__img{
  width: 79px;
  height: 102px;
}