@import '../../theme/styles/base/ovante_variables.scss';

.page-diagnostic {
  .page-content {
    --padding-top: 0px;
    --padding-bottom: 0;
  }
  .stretch-container {
    background: $white;
  }
  .ion-item__label.ion-item__form-business {
    display: block;
    margin: 20px 0 10px;
  }
}
.page-content__header.stretch-container {
  //background: #165f74;
  padding: 30px;
  position: relative;
  height: 210px;
  background-color: #1371B9;
  display: block;
  z-index: 999;
  // background: url('../../assets/images/header-arch.png') #1371B9 no-repeat;
  background: url(../../assets/images/o-device.png) no-repeat #1371B9;
  background-size: 14%;
  background-position: 100% 145%;
  &:after {
    content: "";
    background: url(../../assets/images/header-arch.png) no-repeat;
    position: absolute;
    bottom: -2px;
    z-index: 999;
    display: block;
    width: 100%;
    height: 17px;
    left: 0;
    right: 0;
    background-size: contain;
    @media screen and (min-width: 560px){
      height: 25px;
    }
}
  .header-title {
    color: $white;
    text-align: center;
    font-size: 1.25rem;
    line-height: 1.625rem;
    font-weight: 500;
    margin: 0;
    margin-top: 30px;
    padding: 0 45px;
  }
}
.page-content__range{
  background: $white;
  --bar-background-active: #FE6800;
  --bar-background: #e2e2e2;
  padding: 12px 10px;
  border-radius: 8px;
  --knob-size: 0px;
  --knob-border-radius: 0;
  --height: 4px;
  --knob-background: #FE6800;
  --bar-height: 4px;
  --bar-border-radius: 4px;
  pointer-events: none;
}
