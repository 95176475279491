.main-container {
  height: 100%;
  width: 100%;
}

ion-content {
  width: 100%;
  max-width: 560px;
  margin: 0 auto !important;
  left: 50%;
  transform: translateX(-50%);
}

.page-change-number,
.page-confirm-otp,
.page-create-account,
.page-hearaboutus,
.page-login-with-phone,
.page-other-numbers,
.page-preffer-number,
.page-register,
.page-t-and-c,
.page-account-security {
  .register-header {
    //background-color: #165F74;
    //background-color: $vGreen;
    background-color: transparent;
    background: radial-gradient(105% 45% at bottom, transparent 50%, $vGreen 52%);
    margin-bottom: -10px;
  }
}

.page-content {
  --padding-top: 20px;
  --padding-bottom: 20px;
  --padding-start: 20px;
  --padding-end: 20px;
}

.page-content__title {
  color: $vBlue;
  font-size: 1.125rem;
  line-height: 1.575rem;
  // font-weight: 500;
  font-family: "Ubuntu Medium";
  margin: 0 0 20px 0;
}

.page__section-title {
  font-size: 0.875rem;
  font-family: "Ubuntu Medium";
}

.page-content__infotext {
  color: $vGray;
  font-size: 0.875rem;
  line-height: 1.138rem;
  font-weight: 400;
  padding-bottom: 20px;
  margin: 0;
}

.page-content__button {
  position: absolute;
  bottom: 10px;
  left: 50%;
  padding: 20px;
  width: 100%;
  transform: translateX(-50%);
}

.ion-button__transparent-with-icon {
  --background: transparent;
  --border: 0;
  --box-shadow: none;
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
}

.page-content__link {
  color: $vOrange;
  font-weight: 700;
}

.page-content__otptext {
  color: $vLightBlue;
  font-size: 0.875rem;
  line-height: 1.225rem;
  font-weight: 500;
  margin: 0;
  padding: 17px 0;
  text-decoration: underline;
  cursor: pointer;
}

.page-content__otptext-resent {
  color: $vLightGray;
  font-size: 0.75rem;
  line-height: 1.05rem;
}

.page-content__whitebox {
  display: flex;
  background: $white;
  padding: 18px;
  border-radius: 8px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;
}

.whitebox__socialicon {
  width: 18px;
  height: 18px;
}

.whitebox__content {
  margin: 0;
  padding: 0 20px;
  color: $vLightBlack;
  font-size: 1rem;
  line-height: 1.149rem;
  font-weight: 500;
  width: 100%;
}

ion-list {
  background: transparent !important;
  width: 100%;
}

.m0 {
  margin: 0;
}

.p0 {
  padding: 0;
}
.loader-page-center{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}
.stretch-container {
  margin: 0 -20px;
  padding: 30px 20px;

  .page__section-title {
    margin-bottom: 5px;
  }
}

.relative {
  position: relative;
}

.toast-message {
  --max-width: 80%;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
