.icon__max {
    background: url(../../../assets/images/icons/fullscreen.svg) no-repeat;
}

.icon__min {
    background: url(../../../assets/images/icons/fullscreen-exit.svg) no-repeat;
}
.icon__like {
    background: url(../../../assets/images/icons/like.svg) no-repeat;
}
.icon__play {
    background: url(../../../assets/images/icons/play.svg) no-repeat;
}
.icon__play-fullscreen{
    background: url(../../../assets/images/icons/fullscreen-play.svg) no-repeat;
}
.icon__pause {
    background: url(../../../assets/images/icons/pause.svg) no-repeat;
}
.icon__setting {
    background: url(../../../assets/images/icons/setting.svg) no-repeat;
}
.icon__coins {
    background: url(../../../assets/images/icons/coins-bg.svg) no-repeat !important;
}
.icon__edit-profile{
    background: url(../../../assets/images/icons/edit-profile.svg) no-repeat !important;
}
.icon__all{
    background: url(../../../assets/images/icons/icon-polygon.svg) no-repeat !important;
}

.icon__completed{
    background: url(../../../assets/images/icons/completed.svg) no-repeat !important;
}