@import '../../../theme/styles/base/ovante_variables.scss';

// .register-header-desktop{
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     padding: 30px 50px 0;
//     @media screen and (max-width: 560px) {
//         display: none;
//     }
// }
// .desktop-logo{
//     width: 101px;
//     height: 23.81px;
// }
// .register-header-desktop-download{
//     width: 190px;
//     height: 45px;
// }

// .register-header{
//     position: relative;
//     height: 80px;
//     background-color: $vBlue;
//     display: block;
//     z-index: 999;
//     background: url('../../../assets/images/header-arch.png') $vBlue no-repeat;
//     background-position: bottom;
//     background-size: contain;
//     margin-bottom: 15px;
//     @media screen and (min-width: 560px) {
//         height: 220px;
//         margin-bottom: -20px !important;
//     }
// }

.register-header{
    position: relative;
    width: 100%; 
    height: 87px;
    margin: 0 auto;
    display: block;
    z-index: 999;
    margin-bottom: 15px;
    background: radial-gradient(105% 45% at bottom, transparent 50%, $vBlue 52%);
    @media screen and (min-width: 560px) {
        height: 220px;
        margin-bottom: -20px !important;
    }
}

// .register-header{
//     position: relative;
//     height: 80px;
//     overflow: hidden;
// 	width: 100%;
//     // background-color: $vBlue;
//     display: block;
//     z-index: 999;
//     background-color: transparent;
//     // background: url('../../../assets/images/header-arch.png') $vBlue no-repeat;
//     // background-position: bottom;
//     // background-size: contain;
//     margin-bottom: 15px;
//     &::after{
//         content: "";
//         position: absolute;
//         width: 300%;
//         height: 530%;
//         //top: -25%;
//         left: -100%;
//         background: radial-gradient(ellipse at center, rgba(115,203,229,0) 0%,rgba(115,203,229,0) 50%,rgba(115,203,229,1) 50%,rgba(115,203,229,1) 100%);
//         z-index: -1;
//     }
//     @media screen and (min-width: 560px) {
//         height: 220px;
//         margin-bottom: -20px !important;
//     }
// }

.register-header__withbutton, .register-header__content{
    display: flex;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media screen and (min-width: 560px) {
        max-width: 560px;
        width: 100%;
        top: 30%;
    }
}
.register-header__text{
    color: $white;
    text-align: center;
    font-size: 1rem;
    line-height: 1.3rem;
    font-family: 'Ubuntu Medium';
    margin: 0;
    width: 100%;
    padding: 0 20px;
    @media screen and (min-width: 560px) {
        font-size: 1.125rem;
    }
}
.register-header__withbutton{
    .register-header__text{
        margin-left: -13px;
    }
}
.ion-button__back-btn{
    --background: transparent;
    --border: 0;
    --box-shadow: none;
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    height: 20px;
    .back-icon{
        width: 9px;
        height: 18px;
    }
}
.register-header__content{
    // .register-header__text{
    //     width: 100%;
    // }
    .back-btn{
        display: none;
    }
}
.header-green {
    //background-color: $vGreen;
    background-color: transparent;
    background: radial-gradient(105% 45% at bottom, transparent 50%, $vGreen 52%);
}