@import "../../theme/styles/base/ovante_variables.scss";

.page-introduction {
  .formGroupContainer{
    margin: 0;
  }
  .ion-item{
    --inner-padding-end: 0px;
    --min-height: 43px;
  }
  .ion-item__input{
    --color: #2293B5;
    font-size: 0.875rem;
    line-height: 1rem;
    border: 2px solid $vBlue;
    height: 44px;
    --padding-top: 0px;
    --padding-bottom: 0px;
    --padding-start: 10px !important;
    --padding-end: 10px !important;
  }
}
.page-intro-top-conatiner{
  z-index: 1;
  position: relative;
  min-height: 300px;
  // max-height: 365px;
  // overflow: hidden;
  &:after {
    content: "";
        background: url(../../assets/images/header-arch.png) no-repeat;
        position: absolute;
        bottom: 0px;
        z-index: 999;
        display: block;
        width: 100%;
        height: 17px;
        left: 0;
        right: 0;
        background-size: contain;
        background-position: bottom;
  }
}
.intro-top-img{
  width: 100%;
  //height: 390px;
}
.intro-o-device{
  width: 100%;
  height: 100%;
  position: absolute;
  top: -10px;
  z-index: 1;
}
.page-intro-bottom-conatiner{
  text-align: center;
}
.logo-ovante{
  width: 104px;
  height: 24px;
  margin: 0 auto;
}
ion-slides {
  height: 100%;
  pointer-events: none;
}
ion-slide {
  flex-direction: column;
}
.swiper-pagination{
  position: relative !important;
  bottom: 0px !important;
  margin-bottom: 20px;
}
.page-intro-title{
  font-size: 1.25rem;
  line-height: 1.625rem;
}
.page-intro-desc{
  font-size: 1rem;
  line-height: 1.4rem;
  padding: 0 15px;
}
.intro-next-btn{
  width: 69px;
  height: 39px;
  margin: 0 auto;
}
.intro-go-btn{
  width: 97px;
  height: 39px;
  margin: 0 auto;
}
.hideNextButton{
  display: none;
}
.intro-lang-select{
  position: absolute;
  top: 10px;
  right: 15px;
  width: 85px;
  .lang__down-arrow{
    position: absolute;
    top: 16px;
    right: 10px;
    z-index: 1;
    width: 9px;
    height: 12px;
    display: table;
  }
}
.page-video-intro{
  position: relative;
  top: -20px;
  .ion-video__controllers{
    display: none;
  }
  .page-video-intro-button{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 15px;
    width: 100%;
    .intro-video-btn{
      --background: transparent;
      font-size: 1rem;
      line-height: 1.4rem;
      height: 25px;
      --box-shadow: none;
      text-decoration: underline;
    }
    .intro-video-text{
      font-size: 1.25rem;
      line-height: 1.625rem;
      position: relative;
      bottom: 50px;
    }
  }
}

.page-intro-bottom-conatiner{
  .swiper-slide-active{
    animation: fadeIn 3s;
    -webkit-animation: fadeIn 3s;
    -moz-animation: fadeIn 3s;
    -o-animation: fadeIn 3s;
    -ms-animation: fadeIn 3s;
    &.bottom-conatiner-first-slide{
      animation: none;
    }
  }
}

@media screen and (max-width: 360px) {
  .logo-ovante {
    margin-bottom: 10px !important;
  }
  .page-intro-title{
    margin-bottom: 10px !important;
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  .page-intro-desc{
    line-height: 1.25rem;
    padding: 0px;
    margin-bottom: 6px !important;
  }
  .swiper-pagination{
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 359px) {
  .logo-ovante {
    margin-top: -10px;
    margin-bottom: 10px !important;
  }
  .page-intro-title{
    margin-bottom: 6px !important;
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
  .page-intro-desc{
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
  .swiper-pagination{
    margin-bottom: 10px;
  }
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}