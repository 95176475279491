@import '../../theme/styles/base/ovante_variables.scss';

.page-edit-profile {
  .register-header{
    margin-bottom: -20px;
    //background-color: #73CBE5;
    background-color: transparent;
    background: radial-gradient(105% 45% at bottom, transparent 50%, $vDeepSkyBlue 52%);
  }
  .page-content {
    --padding-top: 0px;
    --padding-bottom: 0;
  }
  .stretch-container {
    background: $white;
    margin-top: 20px;
  }
  .ion-item__label.ion-item__form-business {
    display: block;
    margin: 20px 0 10px;
  }
}
.log-in-fb{
  padding: 8px 18px;
}
.fb-user-name{
  color: #507CC0;
  font-size: 0.75rem;
  line-height: 0.875rem;
  display: block;
}
.icon-logout{
  width: 18px;
  height: 18px;
  display: table;
  z-index: 1;
}