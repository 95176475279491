@import '../../theme/styles/base/ovante_variables.scss';

.home-card__name{
  font-size: 0.75rem;
  line-height: 0.9rem;
  text-transform: uppercase;
  border-radius: 6px;
  padding: 5px 10px 15px;
  max-width: 177px;
  text-align: center;
}
.home-card__image-container{
  margin-top: -13px;
  position: relative;
  &.home-card-without-story{
    box-shadow: 0px 4px 16px rgba(47, 138, 165, 0.2);
    background-color: #FE6800;
    border-radius: 8px !important;
    height: 227px;
  }
}
.card-image-story{
  border-radius: 8px !important;
  overflow: hidden;
  height: 194px;
  width: 100%;
}
.card-image{
  border-radius: 8px 8px 0 0 !important;
  overflow: hidden;
  height: 161px;
  width: 100%;
  // position: relative;
  // &::after{
  //   content: ' ';
  //   display: block;
  //   position: absolute;
  //   bottom: 0;
  //   width: 100%;
  //   height: 67px;
  //   border-radius: 0 0 8px 8px !important;
  //   background-color: $vOrange;
  // }
}
.card-image-border-radius{
  --background: transparent !important;
  --ion-background-color: transparent !important;
}
.home-card__topic-text{
  position: absolute;
  bottom: 20px;
  padding: 0 30px;
  width: 100%;
  font-size: 1rem;
  line-height: 1.125rem;
  &::after{
    content: ' ';
    position: absolute;
    display: block;
    background: url('../../assets/images/icons/icon-polygon-white.svg') no-repeat;
    width: 12px;
    height: 16px;
    right: 35px;
    bottom: 0px;
  }
}
.home-card__story-text{
  font-size: 1.125rem;
  line-height: 1.575rem;
  padding: 10px;
  text-align: left;
  border-radius: 8px;
  margin: 0 12px;
  position: absolute;
  bottom: 20px;
}