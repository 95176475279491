@import '../../theme/styles/base/ovante_variables.scss';

.tool-card__main-container{
  position: relative;
}
.tool-card__container{
  box-shadow: 0px 4px 16px rgba(241, 81, 13, 0.08);
  border-radius: 8px;
  padding: 17px 15px;
  display: flex;
  //align-items: center;
  justify-content: space-between;
  position: relative;
  height: 76px;
}
.tool-card__img{
  position: absolute;
  top: -17px;
  left: 7px;
}
.tool-card-thumbnail-container{
  width: 46px;
  height: 35px;
}
.tool-card-thumbnail-img{
  width: 46px;
  height: 35px;
  border-radius: 8px !important;
}
.tool-card__name{
  font-size: 0.875rem;
  line-height: 1rem;
}
.other__user-container{
  display: flex;
  align-items: center;
  position: relative;
}
.user-top-three-img{
  display: flex;
  align-items: center;
}
.user__img{
  width: 18px;
  height: 18px;
  border-radius: 100% !important;
  overflow: hidden;
  border: 0.6px solid $white;
  filter: drop-shadow(0px 2.05714px 2.05714px rgba(0, 0, 0, 0.12));
  position: absolute;
  z-index: 3;
  &:nth-child(2){
    left: 10px;
    z-index: 2;
  }
  &:nth-child(3){
    left: 20px;
    z-index: 1;
  }
}
.other-user__text, .tool-like-text{
  font-size: 0.625rem;
  line-height: 0.688rem;    
}
.other-user__text{
  padding-left: 41px;
}
.tool-card__like{
  position: absolute;
  top: 17px;
  right: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.tool-like__img, .tool-unlike__img{
  width: 20px;
  height: 18px;
}
.tool-unlike__img{
  opacity: 0.5;
}