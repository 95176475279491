@import '../../../theme/styles/base/ovante_variables.scss';
.profile_image {
  width: auto;
  //width: 100%;
  height: 100%;
  position: relative;
}
.ion-avatar__image {
  width: 75px;
  height: 75px;
  display: block;
  margin: 0 auto;
}
.ion-button__edit-profile {
  --background: transparent;
  --border: 0;
  --box-shadow: none;
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  width: 24px;
  height: 24px;
  background-position: center !important;
  display: block;
  position: absolute;
  top: -5px;
  left: calc(50% + 15px);
}
.ion-avatar{
  width: 75px;
  height: 75px;
  margin: 0 auto;
  --border-radius: 50% !important;
  overflow: hidden;
}