@import "../../theme/styles/base/ovante_variables.scss";

.page-my-stories {
  .register-header {
    //background-color: $vDeepSkyBlue;
    background-color: transparent;
    background: radial-gradient(105% 45% at bottom, transparent 50%, $vDeepSkyBlue 52%);
  }
  .page-content {
    --padding-top: 0px;
    --padding-bottom: 0;
  }
}
.page-stories__card-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.no-data-container {
  display: flex;
  justify-content: center;
  padding-top: 30px;
  font-size: 1.125rem;
}
