@import '../../theme/styles/base/ovante_variables.scss';

.home-story-card__container{
  padding: 10px;
  box-shadow: 0px 4px 16px rgba(241, 81, 13, 0.08);
  border-radius: 8px;
  display: flex;
  align-items: center;
  &.notification__container{
    justify-content: space-between;
    padding: 10px 20px;
    .story-detail__description{
      font-size: 0.75rem;
      line-height: 1.05rem;
      margin-bottom: 0 !important;
      width: auto;
      white-space: unset;
      overflow: unset;
      text-overflow: unset;
    }
  }
}
.home-story-card-thumbnail{
  box-shadow: 0px 4px 16px rgba(241, 81, 13, 0.08);
  display: table;
}
.home-story-card-thumbnail-container{
  width: 51px;
  height: 51px;
}
.home-story-card-thumbnail-img{
  width: 51px;
  height: 51px;
  border-radius: 8px;
}
// .story-img{
//   width: 51px;
//   height: 51px;
//   border-radius: 8px !important;
//   overflow: hidden;
//   box-shadow: 0px 4px 16px rgba(241, 81, 13, 0.08);
//   display: table;
// }
.notification-img{
  width: 39px;
  height: 39px;
  border-radius: 8px !important;
  overflow: hidden;
  box-shadow: 0px 4px 16px rgba(241, 81, 13, 0.08);
  display: table;
}
.story-detail__title{
  font-size: 0.625rem;
  line-height: 0.875rem;
}
.story-detail__description{
  font-size: 0.875rem;
  line-height: 1.225rem;
  width: 230px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.notification-polygon{
  width: 12px;
  height: 16px;
  display: table;
}
.story-detail__days{
  font-size: 0.625rem;
  line-height: 0.875rem;
}