@import "../../../theme/styles/base/ovante_variables.scss";

.page-experts{
    --ion-background-color: #ffffff;
    .register-header{
        margin-bottom: -20px;
        //background-color: $vDeepSkyBlue;
        background-color: transparent;
        background: radial-gradient(105% 45% at bottom, transparent 50%, $vDeepSkyBlue 52%);
        &.match-with-expert-header{
            background: radial-gradient(105% 45% at bottom, transparent 50%, #165F74 52%);
        }
        .register-header__text{
            width: 100%;
        }
    }
}
.match-with-expert-header{
    height: 85px;
    @media screen and (min-width: 560px){
        height: 220px;
    }
    .register-header__text{
        padding: 0 20px;
    }
}
.page-content__info{
    font-size: 0.875rem;
    line-height: 1.138rem;
}
.page-content__current-expert{
    position: relative;
}

.page-content__expert-list{
    margin-top: 20px;
    .expert-card__container{
        &.expert-card__container-with-btn{
            margin-bottom: 40px;
        }
    }
}