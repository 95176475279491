@import '../../theme/styles/base/ovante_variables.scss';

.audio-player-container{
  background: rgba(254, 104, 0, 0.06);
  border: 1px dashed #FFCAA6;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0px 10px;
  // min-width: 85px;
  height: 36px;
  display: flex;
  align-items: center;
  width: max-content;
}
.duration-text{
  font-size: 0.875rem;
  line-height: 1.4rem;
}
.audio-player-btn{
  width: 19px;
  height: 21px;
  margin: 0;
}
.post__audio-play {
  width: 24px;
  height: 24px;
  display: table;
}
.post__audio-pause {
  width: 16px;
  height: 16px;
  display: table;
}