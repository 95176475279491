@import '../../../theme/styles/base/ovante_variables.scss';

.validationError {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.error {
  color: red;
}
