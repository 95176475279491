@import '../../theme/styles/base/ovante_variables.scss';

.page-tool {
    .capsul-card__prevent-overflow {
        margin-top: 80px;
    }
}

.page-earned-coins {
    .reward-content {
        margin-top: 70px;
    }
}

.page-badge {
    .reward-content {
        margin-top: 70px;
    }
}