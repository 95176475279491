@import '../../theme/styles/base/ovante_variables.scss';

.leaderboard-card__container{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ion-avatar-leaderboard, .leaderboard-card-img{
  width: 28px;
  height: 28px;
}
// .leaderboard-card-img{
//   border-radius: 14px !important;
//   overflow: hidden;
//   width: 28px;
//   height: 28px;
// }
.leaderboard-card__number{
  font-size: 0.875rem;
  line-height: 1rem;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.leaderboard-card{
  width: calc(100% - 35px);
  box-shadow: 0px 4px 16px rgba(241, 81, 13, 0.08);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 10px 13px;
}
.leaderboard-user-my-name{
  font-size: 0.5rem;
  line-height: 0.7rem;
}
.leaderboard-user-name{
  font-size: 0.875rem;
  line-height: 1rem;
}
.leaderboard-coin__container{
  display: flex;
  align-items: center;
  margin-left: auto;
  width: 70px;
  .coin-img {
    width: 23px;
    height: 23px;
  }
  .available-coins__text{
    font-size: 0.875rem;
    line-height: 1rem;
  }
}
.leaderboard-share-btn{
  position: absolute;
  bottom: -15px;
  width: 70px;
  height: 33px;
  font-size: 0.875rem;
  line-height: 1rem;
}
.leaderboard-card__my-profile{
  position: relative;
  .leaderboard-card{
    background-color: #FFEFE3 !important;
    border: 1px solid $vOrange;
    box-shadow: 0px 4px 16px rgba(241, 81, 13, 0.08);
  }
  .leaderboard-card__number{
    background: $vOrange;
    color: #ffffff;
  }
  &.leaderboard-card__share{
    .leaderboard-card{
      padding-bottom: 27px;
    }
  }
  &.leaderboard-card__hide-rank{
    .leaderboard-card{
      width: 100%;
    }
  }
  &.leaderboard-card__floated{
    position: fixed;
    width: calc(100% - 40px);
    bottom: 10px;
    &.leaderboard-card__share{
      bottom: 30px;
    }
  }
}