@font-face {   
    font-family: 'Ubuntu Regular';    //This is what we are going to call
    src: url('../../../assets/fonts/Ubuntu-Regular.ttf'),
         url('../../../assets/fonts/Ubuntu-Regular.woff'),
         url('../../../assets/fonts/Ubuntu-Regular.woff2'),
         url('../../../assets/fonts/Ubuntu-Regular.svg'),
         url('../../../assets/fonts/Ubuntu-Regular.eot');
}

@font-face {   
    font-family: 'Ubuntu Medium';    //This is what we are going to call
    src: url('../../../assets/fonts/Ubuntu-Medium.ttf'),
         url('../../../assets/fonts/Ubuntu-Medium.woff'),
         url('../../../assets/fonts/Ubuntu-Medium.woff2'),
         url('../../../assets/fonts/Ubuntu-Medium.svg'),
         url('../../../assets/fonts/Ubuntu-Medium.eot');

}

@font-face {   
    font-family: 'Ubuntu Light';    //This is what we are going to call
    src: url('../../../assets/fonts/Ubuntu-Light.ttf'),
         url('../../../assets/fonts/Ubuntu-Light.woff'),
         url('../../../assets/fonts/Ubuntu-Light.woff2'),
         url('../../../assets/fonts/Ubuntu-Light.svg'),
         url('../../../assets/fonts/Ubuntu-Light.eot');
}

@font-face {   
    font-family: 'Ubuntu Bold';    //This is what we are going to call
    src: url('../../../assets/fonts/Ubuntu-Bold.ttf'),
         url('../../../assets/fonts/Ubuntu-Bold.woff'),
         url('../../../assets/fonts/Ubuntu-Bold.woff2'),
         url('../../../assets/fonts/Ubuntu-Bold.svg'),
         url('../../../assets/fonts/Ubuntu-Bold.eot'),
}

.font-regular {
    font-family: 'Ubuntu Regular';
}

.font-medium {
    font-family: 'Ubuntu Medium';
}

.font-bold {
    font-family: 'Ubuntu Bold';
}

.font-light {
    font-family: 'Ubuntu Light';
}

.size-8 {
    font-size: 0.5rem;
}