@import '../../../theme/styles/base/ovante_variables.scss';

.backdrop {
  width: 100%;
  height: 100%;
  position: absolute;
  background: $black;
  z-index: 9999;
}

.fade-in {
  transition: 0.5s linear all;
  opacity: 0;
  z-index: -1;
}

.fade-out {
  transition: 0.5s linear all;
  opacity: 0.56;
}
