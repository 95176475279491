@import "../../../theme/styles/base/ovante_variables.scss";

.page-home{
    .stepbar-wrapper{
        padding: 1px;
        top: unset;
        bottom: 84px;
    }
    .profile_image{
        width: 24px;
        height: 24px;
        .ion-avatar{
            width: 24px;
            height: 24px;
            margin-left: 6px;
            .ion-avatar__image {
                width: 24px;
                height: 24px;
                box-shadow: 0px 5.64706px 11.2941px rgba(241, 81, 13, 0.2);
            }
        }
    }
}
.home-top__container{
    position: relative;
    padding-bottom: 5px !important;
}
.home-profile-progress{
    box-shadow: 0px 4px 16px rgba(47, 138, 165, 0.1);
    border-radius: 8px;
    padding: 8px 15px;
    max-width: 325px;
    height: 66px;
    width: 100%;
    position: absolute;
    top: -23px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
}
.home-profile-title{
    font-size: 0.75rem;
    line-height: 1.05rem;
    opacity: 0.8;
}
// .header-profile-img{
//     width: 24px;
//     height: 24px;
//     margin-left: 6px;
// }
.home-progress-level, .home-coins{
    border-left: 1px solid rgba($color: $black, $alpha: 0.1);
    padding: 0px 14px;
}
.progress-level__badges{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.progress-level__badge-img{
    width: 26px;
    height: 26px;
}
.progress-level__lock-img{
    width: 18px;
    height: 24px;
    opacity: 0.2;
}
.home-available__coins{
    display: flex;
    align-items: center;
    .coin-img {
        width: 24px;
        height: 24px;
    }
}
.home-coins__text{
    font-size: 0.875rem;
    line-height: 1.225rem;
    margin-left: 5px;
}
.home-title__container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.home-title__text{
    display: flex;
    align-items: center;
    .page-content__title{
        margin-left: 8px;
    }
}
.title-right{
    margin-right: 8px;
    font-size: 1rem;
    line-height: 1.125rem;
}
.home-story-card{
    margin-top: 15px !important;
}
.home-title-polygon{
    width: 12px;
    height: 16px;
}
.leaderboard-description{
    font-size: 0.875rem;
    line-height: 1.225rem;
}
.home-tools__congratulation{
    background: rgba(22, 95, 116, 0.05);
    padding: 10px 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.congratulation-text{
    font-size: 0.875rem;
    line-height: 1.138rem;
}
.congratulation-img{
    width: 21px;
    height: 20px;
    display: table;
}
.home-tools__container{
    .home-tools {
        //display: flex;
        &:nth-child(odd){
            padding-right: 5px;   
        }
        &:nth-child(even){
            padding-left: 5px;   
        }
    }
}
.home-success-popup{
    .popover-content{
        padding: 30px;
    }
}
.home-popup-icon{
    width: 90px;
    height: 91px;
    margin: 0 auto;
}
.home-popup-title{
    font-size: 1.375rem;
    line-height: 1.925rem;
}
.home-popup-congratulation{
    font-size: 0.875rem;
    line-height: 1.138rem;
}
.home-popup-description{
    font-size: 0.875rem;
    line-height: 1.225rem;
}
.home-popup-btn{
    width: 71px;
    height: 39px;
    margin: 0 auto;
}