@import "../../../theme/styles/base/ovante_variables.scss";

.comment-container {
  &:last-child {
    .comment-type-container {
      .item-divider {
        display: none;
      }
    }
  }
}
.userprofile-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile-container,
.comment-button__container {
  display: flex;
  align-items: center;
}
.profile-container {
  .ion-avatar {
    width: 20px;
    height: 20px;
    .ion-avatar__image {
      width: 20px;
      height: 20px;
      filter: drop-shadow(0px 2.28571px 2.28571px rgba(0, 0, 0, 0.12));
    }
  }
}
.userprofile-name,
.comment-button__delete,
.comment-button__edit {
  font-size: 0.625rem;
  line-height: 0.875rem;
}
.comment-type-container {
  .item-divider {
    background: $black;
    opacity: 0.1;
    margin: 10px 0;
  }
  .recorder-post__audio-player {
    height: 36px;
    background: $white;
    box-shadow: 0px 4px 16px rgba(241, 81, 13, 0.08);
    border: 0;
    &.pause-width {
      min-width: 85px;
      background: rgba(254, 104, 0, 0.06);
      border: 1px dashed #ffcaa6;
    }
    .post__audio-play {
      visibility: hidden;
      position: relative;
      width: 17.71px;
      height: 19.28px;
      &::before {
        content: " ";
        display: block;
        position: absolute;
        width: 17.71px;
        height: 19.28px;
        background: url("../../../assets/images/icons/play-story.svg") no-repeat;
        top: 0px;
        left: 0px;
        background-size: 100%;
        visibility: visible;
      }
    }
    .record-btn-text {
      font-size: 0.875rem;
      line-height: 1.4rem;
    }
  }
}
.comment-type-text {
  font-size: 0.875rem;
  line-height: 1.225rem;
  white-space: pre-wrap;
}
.comment-type-video {
  .page-stories__thumbnail-container {
    width: 69px;
    height: 69px;
    display: inline-block;
  }
  .page-stories__thumbnail {
    width: 69px;
    height: 69px;
    border-radius: 8px !important;
    overflow: hidden;
  }
}
.delete-comment-popup {
  .popover-content {
    padding: 20px 25px;
    border-radius: 8px;
  }
  // .delete-popup-container{
  //     width: 300px;
  // }
  .delete-popup-text {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
  .delete-popup-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .delete-popup-button {
    height: 40px;
    width: 80px;
    --border-radius: 8px;
  }
}
