@import '../../../theme/styles/base/ovante_variables.scss';

.ion-item {
  --padding-start: 0px;
  --inner-border-width: 0;
  --inner-padding-end: 0px;
  --background: transparent;
  --border-color: transparent;
  --highlight-color-focused: transparent;
  --color: #7B7470;
}
.ion-item__input, .ion-item__input-prefix {
  --placeholder-color: #928b87;
  --background: #ffffff;
  --color: #38322E;
  font-size: 1.125rem;
  line-height: 1.8rem;
  font-weight: 400;
  border: 2px solid #b6b2af;
  border-radius: 12px;
  height: 50px;
  --padding-start: 15px !important;
  --padding-end: 15px !important;
  &.error-input{
    border: 1px solid #E33C6F;
  }
}
.ion-item__input-prefix {
  --padding-start: 45px !important;
}
.ion-item__label{
  color: #7B7470;
  font-weight: 500;
  font-size: 1.125rem !important;
  line-height: 1.35rem;
  text-overflow: unset !important;
  white-space: unset !important;
  width: 100%;
  max-width: calc(100% - 10px) !important;
}
.item-interactive-disabled{
  .ion-item__label{
    opacity: 1 !important;
  }
  .native-input[disabled].sc-ion-input-md {
    opacity: 1 !important;
}
}
.ion-item__prefix{
  position: absolute;
  bottom: 14px;
  z-index: 3;
  left: 10px;
  color: #959595;
  font-size: 1.125rem;
}