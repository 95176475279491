@import '../../theme/styles/base/ovante_variables.scss';

.page-learning {
    --ion-background-color: #ffffff;
    .page-learning-recommended-container{
        padding: 25px 20px;
    }
    .page-content__title {
        margin-bottom: 0;
    }
    .activity-card__wrapper{
        margin-bottom: 32px;
    }
    .capsul-card {
        margin-bottom: 10px;
    }
    .stepbar-wrapper {
        margin-bottom: -35px;
    }
    .register-header {
        height: 100px;
    }
}