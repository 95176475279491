
.certificates__shelf{
    position: relative;
    background: url("../../assets/images/shelf.svg") no-repeat;
    background-position: bottom;
    background-size: 338px;
    max-width: 338px;
    margin: 0 auto;
    margin-bottom: 45px;
    @media only screen and (max-width: 392px) {
        background-size: contain;
    }
    &.no-certificate{
        height: 67px;
        margin-top: 20px;
    }
}
.certificates__shelf-content{
    font-size: 0.875rem;
    line-height: 1.225rem;
    opacity: 0.5;
    padding: 0 45px;
    font-family: 'Ubuntu Medium';
    text-align: center;
    padding: 0 27px;
}
.certificate__wrapper {
    padding: 0 27px;
    position: relative;
    bottom: -3px;

    ion-col {
        max-width: 33.3%;
        position: relative;
    }
    .certificate-loader{
        position: absolute;
        top: 35%;
        left: 35%;
    }
    .certificate__shelf-image {
        height: 173px;
        
    }
    @media only screen and (max-width: 392px) {
        bottom: 11px;
        .certificate__shelf-image {
            height: auto;
            
        }
    }
   
}


.page-content__certificates {
    @media only screen and (max-width: 392px) {
        .page-content__title {
            margin-bottom: 30px;
        }
    } 
}