@import '../../theme/styles/base/ovante_variables.scss';

.page-your-badges { 
    .register-header {
        margin-bottom: 0;
        background: none;
        height: 62px;
        .register-header__withbutton{
            top: 50%;
        }
    }
    .page-content {
        background: transparent;
        --background: transparent;
        --padding-top: 0px;
    }
    .page-content__title{
        margin-bottom: 5px;
    }
    .page-badges__instructions{
        font-size: 0.875rem;
        line-height: 1.138rem;
    }
    .bottom-box {
        margin-top: 20px;
        // position: absolute;
        // width: 100%;
        bottom: -30px;
        text-align: left;
        position: relative;
        width: auto;
        height: auto;
        &:after {
            content: "";
            background: url(../../assets/images/header-arch.png) no-repeat;
            position: absolute;
            top: -14px;
            z-index: 999;
            display: block;
            width: 100%;
            height: 17px;
            left: 0;
            right: 0;
            background-size: contain;
        }
        // .page-badge__badge-list{
        //     display: block;
        //     font-size: 0;
        //     .badge-list {
        //         display: inline-block;
        //         width: 33.33%;
        //         text-align: left;
        //             &:nth-child(1) {
        //                 .profile__badge-image{
        //                     margin-left: 0;
        //                 }
                    
        //         }
        //         &:nth-child(3) {
        //             text-align: right;
        //             .profile__badge-image{
        //                 margin-right: 0;
        //             }
        //         }
        //     }
        // }
        .page-badge__badge-list{
            //justify-content: space-between;
            text-align: center;
            .badge-list {
                width: 60px;
                margin-bottom: 0;
                &:nth-child(1) {
                    margin-right: auto;
                }
                &:nth-child(3) {
                    margin-left: auto;
                }
            }
            &.empty-col{
                &::after{
                    content: ' ';
                    display: flex;
                    min-width: 60px;
                    margin-left: auto;
                }
            } 
            &:last-child{
                margin-bottom: 16px;
            } 
        }
    }
}

.abstract-img-light-blue {
    background: url(../../assets/images/reward-bg-blue.png) no-repeat;  
    background-color: #73CBE5;
    background-size: cover;
}

.page-badges__content{
    border-radius: 8px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 0px 4px 16px rgba(241, 81, 13, 0.08);
    text-align: center;
}
.page-badge__badge-list{
    display: flex;
    //align-items: center;
    margin-top: 18px;
    //margin-bottom: 20px;
    align-content: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    .badge-list{
        width: 33%;
        margin-bottom: 16px;
    }
    .profile__badge-image{
        width: 60px;
        height: 60px;
        display: block;
        margin: 0 auto;
        // &.badge-image-large{
        //     width: 75px;
        // }
    }
    .profile__badge-label{
        font-size: 0.625rem;
        line-height: 0.813rem;
        font-family: 'Ubuntu Medium';
    }
}

.large-header--gradient-blue {
    background: rgb(115,203,229);
    background: linear-gradient(180deg, rgba(115,203,229,1) 0%, rgba(115,203,229,1) 66%, rgba(253,246,241,1) 66%, rgba(253,246,241,1) 100%);
}

.large-header--gradient {
   height: 100%;
    .large-header {
        background-position: center;
        background-color: transparent;
    }
}