@import '../../theme/styles/base/ovante_variables.scss';

.page-my-rewards {
    .page-content__title {
        margin-bottom: 0;
    }
    .register-header {
        //background-color: #73CBE5;
        margin-bottom: 0;
        background-color: transparent;
        background: radial-gradient(105% 45% at bottom, transparent 50%, $vDeepSkyBlue 52%);
    }
}  