@import '../../theme/styles/base/ovante_variables.scss';

.page-reward-detail {
    .page-content{
        --padding-top: 0px;
    }
}
.page-content__header-container{
    // background: $black;
    // padding: 20px;
    // position: relative;
    padding: 20px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    .page-content__header{
        display: flex;
        align-items: center;
    }
    // &::after{
    //     content: ' ';
    //     background: url("../../assets/images/header-arch.png") no-repeat;
    //     position: absolute;
    //     background-position: bottom;
    //     display: block;
    //     height: 52px;
    //     background-size: contain;
    //     bottom: 0;
    //     width: 100%;
    //     left: 0;
    // }
}
.reward-brand__img-container{
    //width: 360px;
    height: 406px;
    position: relative;
    // top:0;
    // left: 0;
    margin: 0 -20px;
    background: $black;
    &::after{
        content: ' ';
        background: url("../../assets/images/header-arch.png") no-repeat;
        position: absolute;
        background-position: bottom;
        display: block;
        height: 52px;
        background-size: contain;
        bottom: 0;
        width: 100%;
        left: 0;
    }
}
.reward-brand__img{
    //height: 406px;
    width: 237px;
    height: 237px;
    border-radius: 37.92px !important;
    overflow: hidden;
    margin: 0 auto;
    //border: 1px solid white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.page-content__available-coins{
    padding: 5px 15px;
    box-shadow: 0px 4px 6px rgba(241, 81, 13, 0.08);
    border-radius: 8px;
    width: 160px;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
.available-coins__text{
    font-size: 0.625rem;
    line-height: 0.688rem;
    margin-left: 8px;
}
.reward-detail__container{
    text-align: center;
}
.reward-detail__name{
    font-size: 1.375rem;
    line-height: 1.925rem;
    margin: 0 -10px;
}
.reward-detail__coins{
    display: flex;
    align-items: center;
    justify-content: center;
}
.reward-detail__coins-text{
    font-size: 0.875rem;
    line-height: 1.225rem;
    margin-left: 8px;
}
.reward-detail__description{
    font-size: 0.875rem;
    line-height: 1.225rem;  
}
.reward-detail__btn{
    width: 236px;
    height: 39px;
    margin: 0 auto;
    letter-spacing: 0.01em;
}
.reward-detail__balance-text{
    font-size: 0.75rem;
    line-height: 1.05rem;
}
.reward-detail__enough-coin{
    font-size: 0.875rem;
    line-height: 1rem;
    padding: 0 55px;
}

.reward-popup{
    .reward-popup-btn{
        width: 93px;
        height: 39px;
        margin: 0 auto;
    }
    .ion-popup__no-reward{
        font-size: 1rem;
        line-height: 1.4rem;
        text-decoration: underline;
    }
}