@import '../../theme/styles/base/ovante_variables.scss';

.page-stories__thumbnail-container {
    position: relative;
    .page-stories__play{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
    }
  }
  .page-stories__thumbnail{
    height: 74px;
    width: 100%;
  }
  .page-stories__ion-card {
    background: $white;
    //margin: 5px;
    width: calc(50% - 5px);
    margin-bottom: 10px;
    box-shadow: 0px 4px 16px rgba(241, 81, 13, 0.08);
    border-radius: 8px;
  }
  
  .page-stories__header {
    margin-bottom: 30px;
    padding: 20px 10px 0px;
  }
  
  .page-stories__content {
    position: absolute;
    bottom: 10px;
    width: 100%;
    padding: 0 10px;
  }
.page-stories__title {
  font-size: 0.875rem;
  line-height: 1rem;
  margin: 0;
}
.page-stories__name{
  font-size: 0.625rem;
  line-height: 0.875rem;
  text-transform: uppercase;
}
.story-new-icon{
  width: 47px;
  height: 20px;
  position: absolute;
  top: 5px;
  right: 4px;
}
.page-stories__awaiting{
  font-size: 0.625rem;
  line-height: 0.875rem;
}