@import "../../theme/styles/base/ovante_variables.scss";

.fullscreen {
  width: 100% !important;
  position: relative;
  .react-player {
    position: relative;
    padding-top: 56.25%;
    overflow: hidden;
    height: 0;

    video {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .react-player__preview {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .icon__play {
    width: 119px;
    height: 130px;
    border: none;
    font-size: 0;
    &.hide-thumnail-play-icon{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.ion-video__content {
  position: relative;
}

.ion-video__controllers {
  display: flex;
  width: 100%;
  position: absolute;
  bottom: 10px;
  left: 0;
  padding: 0 15px;
  justify-content: space-between;
}

.ion-video__time {
  color: $white;
  font-size: 0.75rem;
  line-height: 1.2rem;
}

.ion-video__icon {
  width: 28px;
  height: 28px;
  font-size: 0;
  background-position: center !important;
  display: block;
  border: 1px solid white;
  border-radius: 8px;
  padding: 5px;
  &.icon__min,
  &.icon__pause,
  &.icon__play-fullscreen {
    border: 0;
  }
  &.ion-video__icon-black{
    border: 1px solid #616161;
  }
}

.icon__like__text {
  font-size: 0.625rem;
  color: $white;
  margin-top: 5px;
  align-content: center;
  display: flex;
  justify-content: center;
  &.icon__like__text-black{
    color: #616161;
  }
}

.ion-video__controllers_align-left {
  display: flex;
}

.ion-video__controllers_align-right {
  display: flex;
}

.ion-video__progress-bar {
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type="range"] {
      overflow: hidden;
      width: 100% !important;
      -webkit-appearance: none;
      // background-color: #9a905d;
      background-color: #f3f2f2;
    }

    input[type="range"]::-webkit-slider-runnable-track {
      height: 20px;
      -webkit-appearance: none;
      color: #13bba4;
      margin-top: -1px;
    }

    input[type="range"]::-webkit-slider-thumb {
      width: 20px;
      -webkit-appearance: none;
      height: 20px;
      cursor: ew-resize;
      background: $vOrange;
      box-shadow: -260px 0 0 260px $vGreen;
    }
  }

  /** FF*/
  input[type="range"]::-moz-range-progress {
    background-color: #43e5f7;
  }

  input[type="range"]::-moz-range-track {
    background-color: #9a905d;
  }

  /* IE*/
  input[type="range"]::-ms-fill-lower {
    background-color: #43e5f7;
  }

  input[type="range"]::-ms-fill-upper {
    background-color: #9a905d;
  }
}

.fullscreen-enabled {
  & > div {
    height: calc(100% - 20px) !important;
  }
}
