@import "../../../theme/styles/base/ovante_variables.scss";

.page-stories {
  .register-header {
    //background-color: $vDeepSkyBlue;
    background-color: transparent;
    background: radial-gradient(105% 45% at bottom, transparent 50%, $vDeepSkyBlue 52%);
    margin-bottom: 0;
  }
  .page-content{
    --padding-top: 10px;
    margin-top: -5px;
    position: relative;
  }
  .page-stories__ion-card{
      margin-bottom: 20px;
  }
  .page-stories__header{
    padding-top: 15px;
  }
}
.page-stories__description{
  font-size: 0.875rem;
  line-height: 1.138rem;
}
.page-stories__bottom-container{
  padding: 0 20px 45px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  bottom: 0px;
  z-index: 1;
  &::before{
    content: ' ';
    height: 100%;
    width: 100%;
    background: url('../../../assets/images/feature_bg.svg') no-repeat;
    background-position: top;
    background-size: contain;
    position: absolute;
    top: -37px;
    left: 0;
    z-index: -1;
  }
}
.bottom-container_details{
  padding-right: 10px;
}
.page-stories__badge{
  font-size: 0.625rem;
  line-height: 0.875rem;
}
.page-stories__badge-desc{
  font-size: 0.75rem;
  line-height: 0.875rem;
}
.page-stories__submit-btn{
  width: 130px;
  height: 39px;
}
.page-stories__bottom-drawer{
  .bottom-drawer-container{
    .bottom-drawer{
      --background: transparent !important;
      border-radius: 0px !important;
      margin: 0;
      box-shadow: none;
      // .drawer-back{
      //   display: none;
      // }
    }
  }
}