@import '../../theme/styles/base/ovante_variables.scss';

.page-hearaboutus{
    .page-content__whitebox{
        padding: 10px 18px;
        .whitebox__socialicon{
            width: 40px;
            height: 30px;
        }
        .whitebox__right-arrow{
            display: table;
            width: 11px;
            height: 14px;
        }
    }
}