@import '../../theme/styles/base/ovante_variables.scss';

.page-help-center {
    .register-header {
        height: 185px;
        //@debug: #73CBE5;
        margin-bottom: -10px;
        background-color: transparent;
        background: radial-gradient(105% 25% at bottom, transparent 50%, $vDeepSkyBlue 52%);
        .register-header__withbutton{
            top: 29px;
            z-index: 3;
        }
        &::before{
            content: ' ';
            background: url("../../assets/images/help-center.svg") no-repeat;
            position: relative;
            background-position: bottom;
            display: block;
            height: 100%;
            max-width: 232px;
            margin: 0 auto;
            background-size: contain;
            z-index: 1;
            bottom: 20px;
        }
        &::after{
            content: ' ';
            background: url("../../assets/images/header-arch.png") no-repeat;
            position: relative;
            background-position: bottom;
            display: block;
            height: 100%;
            background-size: contain;
            z-index: 2;
            bottom: 105%;
        }
    }
}
.page-content__intro{
    font-size: 0.875rem;
    line-height: 1rem;
}
.page-content__instruction{
    font-size: 0.75rem;
    line-height: 0.975rem;  
}

.page-content__accordion-btn{
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 4px 16px rgba(47, 138, 165, 0.2);
    position: relative;
    &::after{
        content: ' ';
        display: block;
        position: absolute;
        width: 14px;
        height: 11px;
        background: url("../../assets/images/icons/icon-polygon-down-arrow.svg") no-repeat;
        z-index: 1;
        top: 28px;
        right: 22px; 
    }
    &.accordion-btn__active{
        &::after{
            background: url("../../assets/images/icons/icon-polygon-up-arrow.svg") no-repeat;
        }
    }
}

.page-content__img{
    border-radius: 10px !important;
    overflow: hidden;
}

.page-content__description{
    font-size: 0.875rem;
    line-height: 1.138rem;
}

.page-content__contact-btn{
    display: flex;
    justify-content: flex-end;
    a {
        text-decoration: none;
    }
}

.btn-contact-us{
    --border-width: 0px;
    box-shadow: 0px 4px 16px rgba(241, 81, 13, 0.08);
    border-radius: 8px;
    color: $black;
    font-size: 0.875rem;
    line-height: 1.138rem;
    width: 134px;
    height: 37px;
    --padding-start: 1.7em;
    --padding-end: 0;
    position: relative;
    &::before{
        content: ' ';
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        background: url("../../assets/images/icons/icon-whatsup.svg") no-repeat;
        z-index: 1;
        top: 6.5px;
        left: 11px;
    }
}