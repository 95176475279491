@import '../../theme/styles/base/ovante_variables.scss';

.reward-card__container {
  background: $white;
  border-radius: 8px;
  padding: 6px 20px;
  position: relative;
  min-height: 72px;
  height: auto;
  margin-bottom: 10px;
  box-shadow: 0px 4px 16px rgba(47, 138, 165, 0.2);
  display: flex;
  align-items: center;
}
.reward-card__img{
  position: relative;
}
.ion-thumbnail-reward{
  width: 50px;
  height: 50px;
}
.reward-img{
  width: 50px;
  height: 50px;
  border-radius: 8px;
}
.reward-lock{
  position: absolute;
  top: -4px;
  right: -3px;
}
.reward-lock-img{
  width: 14px;
  height: 14px;
}
.reward-card__text-container{
  padding: 0 9px;
}
.reward-card__title{
  font-size: 0.875rem;
  line-height: 1.225rem;
  margin-bottom: 2px;
}
.reward-card__description-redeemed{
  font-size: 0.625rem;
  line-height: 0.875rem;
  color: rgba(0, 0, 0, 0.45);
}
.description-redeemed__date{
  color: $vMaroon;
}
.reward-card__description{
  font-size: 0.688rem;
  line-height: 0.894rem;
}
.reward-card__coins{
  display: flex;
  align-items: center;
}
.coin-img{
  width: 18px;
  height: 18px;
}
.coins-text{
  font-size: 0.625rem;
  line-height: 0.875rem;
  margin-left: 4px;
}
.reward-card__arrow{
  margin-left: auto;
  margin-right: 6px;
}
.reward-arrow{
  width: 12px;
  height: 16px;
}

