@import '../../theme/styles/base/ovante_variables.scss';

.page-redeemed-rewards {
    --ion-background-color: #ffffff;
    .page-content__title {
        margin-bottom: 0;
    }
    .register-header {
        //background-color: #73CBE5;
        background-color: transparent;
        background: radial-gradient(105% 45% at bottom, transparent 50%, $vDeepSkyBlue 52%);
        margin-bottom: 0;
    }
    &.page-redeemed-rewards-orange{
        --ion-background-color: #FDF6F1;
    }
}
.current-rewards{
    padding: 20px 20px 10px;
}
.expired-rewards{
    padding: 25px 20px;
}
.expired-rewards__title{
    font-size: 0.875rem;
    line-height: 1rem;
}