@import '../../../theme/styles/base/ovante_variables.scss';

.formGroupContainer {
  margin-top: 20px;
  margin-bottom: 20px;
  .ion-item{
    .textarea-wrapper{
      height: 50px;
    }
  }
  .readonly{
    position: relative;
    .readonly__down-arrow{
      position: absolute;
      top: calc(50% + 9px);
      right: 20px;
      z-index: 1;
      transform: translateY(calc(-50% + 11px));
    }
    .readonly__right-arrow{
      position: absolute;
      top: 50%;
      right: 20px;
      z-index: 1;
      transform: translateY(-50%);
    }
  }
}
