@import '../../theme/styles/base/ovante_variables.scss';

.like-container{
    display: flex;
    //align-items: center;
}
.like__image{
    width: 13px;
    height: 12px;
    margin-right: 3px;
}
.likes__count{
    color: $vLightBlack;
    font-size: 0.625rem;
    line-height: 0.875rem;
}