@import "../../theme/styles/base/ovante_variables.scss";

.page-community-story {
  .register-header {
   // background-color: $vDeepSkyBlue;
    background-color: transparent;
    background: radial-gradient(105% 45% at bottom, transparent 50%, $vDeepSkyBlue 52%);
    margin-bottom: 10px;
  }
  .page-content {
    --padding-top: 0px;
    //--padding-bottom: 75px
  }
  .ion-item {
    background: transparent;
    width: 100%;
    height: unset;
    text-align: left;
    --min-height: auto;
    border-radius: unset;
    --padding-start: 2px;
  }
  .ion-item__input {
    --background: transparent;
    --color: #000000;
    font-size: 1.125rem;
    line-height: 1.8rem;
    border: 0;
    border-radius: 0px;
    width: 100%;
    --padding-start: 0px !important;
    --padding-end: 0px !important;
    --padding-top: 0px;
    --padding-bottom: 0px;
  }
}
.page-community-story__header {
  position: relative;
}
.page-community__share-btn {
  position: absolute;
  right: 15px;
  z-index: 1000;
  width: 24px;
  height: 24px;
  top: 17%;
}
.share-btn {
  width: 24px;
  height: 24px;
}
.community-story__container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: calc(100% - 75px);
}
.community-story__video-container {
  padding: 0;
}
.community-story__comments {
  height: 100%;
  position: relative;
  .empty-comment__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  &.story-comment__add-padding {
    padding-bottom: 75px;
  }
}
.community-story__comments-list{
  padding-bottom: 75px;
}
.text-comment {
  font-size: 0.875rem;
  line-height: 1.225rem;
}
.page-content__add-comment {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 8px 20px;
  height: auto;
  z-index: 999;
  &::before {
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    height: 15px;
    background: url("../../assets/images/add-task-bg.svg") no-repeat;
    top: -10px;
    left: 0px;
    background-size: 100%;
  }
}
.add-comment {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.comment-btns {
  display: flex;
  align-items: center;
}
.comment-mic,
.comment-video-camera {
  width: 24px;
  height: 24px;
  display: table;
}
.post-btn {
  width: 77px;
  height: 35px;
}
.recorder-instruction__container {
  display: flex;
  align-items: center;
}
.audio-recorder__polygon {
  width: 12px;
  height: 16px;
  display: table;
}
.recorder-instruction {
  font-size: 1rem;
  line-height: 1.125rem;
}
.record-btn__container {
  background: rgba(254, 104, 0, 0.06);
  border: 1px dashed #ffcaa6;
  border-radius: 8px;
  padding: 0px 10px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.mic-orange {
  width: 24px;
  height: 24px;
  display: table;
}
.record-btn-text {
  font-size: 1.125rem;
  line-height: 1.8rem;
}
.recorder-btn__container {
  background: rgba(240, 0, 0, 0.06);
  border: 1px solid #ffaaaa;
  border-radius: 8px;
  padding: 0px 10px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.recorder-stop-btn {
  width: 24px;
  height: 24px;
  display: table;
}
.stop-recording__text {
  font-size: 0.75rem;
  line-height: 1.2rem;
  opacity: 0.6;
  text-align: center;
}
.recorder-post-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.recorder-post__audio-player {
  background: rgba(254, 104, 0, 0.06);
  border: 1px dashed #ffcaa6;
  border-radius: 8px;
  padding: 0px 10px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 123px;
}
.post-btn-container {
  display: flex;
  align-items: center;
  .redo-btn,
  .post-btn {
    width: 77px;
    height: 43px;
  }
}
