@import '../../theme/styles/base/ovante_variables.scss';

.profile__details {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  &.ion-padding {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.profile__level,
.profile__coins {
  width: auto;
  text-align: center;
  .ion-item {
    background: $vGreen;
    box-shadow: 0px 4px 16px rgba(241, 81, 13, 0.08);
    border-radius: 100%;
    width: 36px;
    height: 36px;
    --min-height: 36px;
    text-align: center;
  }
  .ion-item__input {
    --background: transparent;
    --color: #FFFFFF;
    font-size: 1rem;
    line-height: 1.4rem;
    border: 0;
    border-radius: 0px;
    width: 36px;
    height: 36px;
    --padding-start: 0px !important;
    --padding-end: 0px !important;
    --padding-top: 0px;
    --padding-bottom: 0px;
  }
  .ion-item__label {
    color: $white;
    font-size: 0.625rem !important;
  }
}
.profile__coins {
  .ion-item {
    background: transparent;
  }
  .coins-bg-image {
    position: absolute;
  }
}
.ion-avatar__container {
  width: auto;
  //width: 100%;
  height: 100%;
  position: relative;  
}
.ion-label__profile {
  display: block;
  text-align: center;
  color: $white;
  .profile__name {
    font-size: 1.125rem;
    line-height: 1.575rem;
    font-family: "Ubuntu Medium";
  }
  .profile__joining {
    font-size: 0.625rem;
    line-height: 0.875rem;
  }
}
