@import "../../theme/styles/base/ovante_variables.scss";

.page-congratulations{
    .page-content{
        --padding-top: 75px;
    }
}

.page-congratulations__btn-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 12px 0 27px;
    left: 20px;
    &:after {
        content: "";
        background: url(../../assets/images/header-arch.png) no-repeat;
        position: absolute;
        top: -15px;
        z-index: 999;
        display: block;
        width: 100%;
        height: 17px;
        left: 0;
        background-size: contain;
        @media screen and (min-width: 560px){
            background-size: cover;
        }
    }
}
.page-congratulations__btn{
    width: 101px;
    height: 44px;
    margin: 0 auto;
}
.page-congratulations__container{
    text-align: center;
    @media screen and (min-width: 560px){
        position: relative;
        top: 40%;
        transform: translateY(-40%);
    }
}
.congratulations-title, .congratulations-step{
    font-size: 1.563rem;
    line-height: 1.813rem;
}
.congratulations-medal{
    font-size: 1.125rem;
    line-height: 1.313rem;
}
.medal-img__container{
    .medal-img{
        width: 140px;
        height: 140px;
        margin: 0 auto;
    }
}
.congratulations-intro-video, .congratulations-level__earn-badge{
    font-size: 0.75rem;
    line-height: 1.05rem;
}
.congratulations-top-three__container{
    display: flex;
    justify-content: center;
}
.top-three__container-inner{
    display: flex;
    align-items: center;
    position: relative;
}
.congratulations-top-three-img{
    display: flex;
    align-items: center;
}
.congratulations-user__img{
    width: 18px;
    height: 18px;
    border-radius: 100% !important;
    overflow: hidden;
    border: 0.6px solid $white;
    filter: drop-shadow(0px 2.05714px 2.05714px rgba(0, 0, 0, 0.12));
    position: absolute;
    z-index: 3;
    &:nth-child(2){
        left: 10px;
        z-index: 2;
    }
    &:nth-child(3){
        left: 20px;
        z-index: 1;
    }
}
.congratulations-user__text{
    font-size: 0.625rem;
    line-height: 0.875rem;
    padding-left: 41px;
}
.congratulations-level__container{
    background: rgba(255, 255, 255, 0.24);
    border: 1px solid #FFFFFF;
    border-radius: 8px;
    max-width: 160px;
    width: 100%;
    margin: 0 auto;
}
.congratulations-progress-level{
    padding: 6px 20px;
}
.congratulations-progress-title{
    text-align: left;
    font-size: 0.625rem;
    line-height: 0.875rem;
    margin-bottom: 2px;
}
.congratulations-progress-level__badges{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .progress-level__badge-img{
        width: 14px;
        height: 14px;
    }
    .progress-level__lock-img{
        width: 11px;
        height: 14px;
        opacity: 0.2;
    }
}