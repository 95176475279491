@import "../../theme/styles/base/ovante_variables.scss";

.expert-card__container {
  // background: linear-gradient(82.12deg, #73cbe5 3.47%, #45b7d9 98.9%);
  border-radius: 8px;
  padding: 15px 20px;
  position: relative;
  min-height: 140px;
  height: auto;
  margin-bottom: 20px;
  &::after {
    content: " ";
    display: block;
    position: absolute;
    background: url("../../assets/images/o-device-expert.svg") no-repeat;
    //background-size: contain;
    width: 145px;
    height: 103px;
    right: 0;
    bottom: 0;
  }
  &.expert-card__container-with-btn {
    margin-bottom: 20px;
  }
  .stepbar-wrapper {
    display: inline-block;
    margin: 0;
    width: 52%;
    top: unset;
    margin-top: 10px;
    .progress li {
      width: 20%;
    }
  }
}
.expert-card__complete {
  font-size: 0.875rem;
  line-height: 1.1rem;
  margin: 15px 0;
  display: flex;
  align-items: center;
  justify-content: start;
  .expert-card__complete-checked {
    width: 21px;
    height: 21px;
  }
}
.expert-card__title {
  font-size: 1.125rem;
  line-height: 1.575rem;
}
.expert-card__name {
  font-size: 0.875rem;
  line-height: 1.225rem;
}
.expert-card__img {
  position: absolute;
  right: 0px;
  bottom: 0;
  z-index: 1;
  width: 102px;
  height: 124px;
}
.expert-card__know-me {
  font-size: 0.875rem;
  line-height: 1.225rem;
  text-transform: none;
  .ion-button__know-me {
    margin-left: 5px;
  }
}

.expert__card-btn {
  position: absolute;
  bottom: -19px;
  left: 18px;
  width: auto;
  max-width: 225px;
  height: 39px;
  --padding-start: 16px;
  --padding-end: 16px;
  letter-spacing: 0.025em;
  z-index: 2;
  &.get-start___btn {
    width: 159px;
    text-align: left;
    --padding-start: 10px;
    --padding-end: 53px;
    &::after {
      content: " ";
      display: inline-block;
      width: 10px;
      height: 13px;
      background: url("../../assets/images/icons/icon-polygon-white.svg")
        no-repeat;
      margin-left: 10px;
      position: absolute;
      top: 13px;
      right: 15px;
    }
  }
}
.expert-card__new {
  position: absolute;
  width: 71px;
  height: 74px;
  top: -14px;
  left: -20px;
}
