.mb-3 {
    margin-bottom: 3px !important;
}
.mb-5 {
    margin-bottom: 5px !important;
}
.mb-8 {
    margin-bottom: 8px !important;
}
.mb-10 {
    margin-bottom: 10px !important;
}
.mb-15 {
    margin-bottom: 15px !important;
}
.mb-20 {
    margin-bottom: 20px !important;
}
.mb-25 {
    margin-bottom: 25px !important;
}
.mb-30 {
    margin-bottom: 30px !important;
}
.mb-40 {
    margin-bottom: 40px !important;
}
.mb-80 {
    margin-bottom: 80px !important;
}
.mt-5 {
    margin-top: 5px !important;
}
.mt-10 {
    margin-top: 10px !important;
}
.mt-15 {
    margin-top: 15px !important;
}
.mt-20 {
    margin-top: 20px !important;
}
.mt-25 {
    margin-top: 25px !important;
}
.mt-30 {
    margin-top: 30px !important;
}
.mt-40 {
    margin-top: 40px !important;
}
.mt-0 {
    margin-top: 0 !important;
}
.mb-0 {
    margin-bottom: 0 !important;
}
.ml-0 {
    margin-left: 0 !important;
}
.ml-5 {
    margin-left: 5px !important;
}
.ml-6 {
    margin-left: 6px !important;
}
.ml-10 {
    margin-left: 10px !important;
}
.mr-0 {
    margin-right: 0 !important;
}
.mr-5 {
    margin-right: 5px !important;
}
.mr-10 {
    margin-right: 10px !important;
}
.mr-20 {
    margin-right: 20px !important;
}
.pb-20 {
    padding-bottom: 20px !important;
    --padding-bottom: 20px !important;
}
.pb-10 {
    padding-bottom: 10px !important;
    --padding-bottom: 10px !important;
}
.pb-85 {
    padding-bottom: 85px !important;
    --padding-bottom: 85px !important;
}
.pb-125 {
    padding-bottom: 125px !important;
    --padding-bottom: 125px !important;
}
.pb-60 {
    padding-bottom: 60px !important;
    --padding-bottom: 60px !important;
}
.pb-80 {
    padding-bottom: 80px !important;
    --padding-bottom: 80px !important;
}
.pb-5 {
    padding-bottom: 5px !important;
    --padding-bottom: 5px !important;
}
.pt-0 {
    padding-top: 0 !important;
    --padding-top: 0 !important;
}
.pt-20 {
    padding-top: 20px !important;
    --padding-top: 20px !important;
}
.pt-30 {
    padding-top: 30px !important;
    --padding-top: 30px !important;
}
.pb-0 {
    padding-bottom: 0 !important;
    --padding-bottom: 0 !important;
}
.pl-0 {
    padding-left: 0 !important;
    --padding-left: 0 !important;
}
.pr-0 {
    padding-right: 0 !important;
    --padding-right: 0 !important;
}