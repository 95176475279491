@import '../../theme/styles/base/ovante_variables.scss';

.page-start-quiz{
    background: $vGreen;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    .register-header {
        background-color: $vGreen;
        background: unset;
        margin-bottom: 0;
        //background-color: $vGreen;
        @media screen and (min-width: 560px){
            height: 80px !important;
            .register-header__withbutton{
                top: 50%;
            }
        }
    }
    .page-content{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        text-align: center;
        flex-direction: column;
    }
    
    // @media screen and (min-width: 560px){
    //     .page-content__buttonquiz{
    //         height: 150px;
    //         background: #FDF6F1;
    //         border: 5px solid $vGreen;
    //     }
    //     .bottom-curve-mobile{
    //         display: none;
    //     }
    // }
}
.page-content__rocket-icon{
    width: 60px;
    height: 60px;
}
.page-content__quiz_instruction{
    color: $white;
    font-size: 1.125rem;
    line-height: 1.575rem;
    font-weight: 700;
    margin: 0;
    margin-top: 20px;
}
.page-content__quiz_info{
    color: $white;
    font-size: 0.875rem;
    line-height: 1.225rem;
    font-weight: 500;
    margin: 0;
    margin-top: 20px;
}
.page-content__buttonquiz{
    position:relative;
    .button-quiz{
        width: 90%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}